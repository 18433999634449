import './src/styles/global.scss'

export const onRouteUpdate = () => {

    if (typeof window !== 'undefined') {

      // Define your groups of pathnames
      const group1Pathnames = ['/overview-of-t-cell-therapies-for-solid-tumors', '/role-of-t-cell-receptors', '/role-of-biomarkers-in-t-cell-receptor-t-cell-therapies', '/t-cell-therapy-treatment-process']
      const group2Pathnames = ['/antigen-expression-in-solid-tumors', '/mage-a4-expression-across-tumors']

      // Check if the current pathname matches any in group 1
      if (group1Pathnames.some(pathname => window.location.pathname.startsWith(pathname))) {
        document.getElementById( 'link-0' ).getElementsByTagName( 'a' )[0].classList.add('active-menu')
      }

      // Check if the current pathname matches any in group 2
      if (group2Pathnames.some(pathname => window.location.pathname.startsWith(pathname))) {
        document.getElementById( 'link-1' ).getElementsByTagName( 'a' )[0].classList.add('active-menu')
      }
    }
};
  